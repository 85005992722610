addEventListener("DOMContentLoaded", () => {
    let hash = location.hash.replace(/^#tab-/, '')
    if (hash) {
        let targetEl = document.querySelector('.nav-pills a[href="#' + hash + '"]')
        if (targetEl) {
            const tabTrigger = new bootstrap.Tab(targetEl)
            tabTrigger.show()
        }
    }
    document.addEventListener('shown.bs.tab', (event) => {
        if (event.target.closest('.nav-pills a')) {
            window.location.hash = 'tab-' + event.target.hash.replace('#', '')
        }
    });
    if (document.getElementById('subscribeCheck')) {
        document.getElementById('subscribeCheck').addEventListener('change', (event) => {
            document.getElementById('ameriaDonateBtn').innerHTML = document.getElementById('ameriaDonateBtn').dataset[event.target.checked ? 'subscribeText' : 'donateText'];
        })
    }
    new ClipboardJS('.btn-copy');

    const triggerList = document.querySelectorAll('[data-bs-toggle="show"]')
    const popoverList = [...triggerList].map(triggerEl => {
        triggerEl.addEventListener('click', () => {
            document.querySelectorAll(triggerEl.dataset['bsTarget']).forEach((el) => {
                el.classList.remove('hide')
                triggerEl.classList.add('hide');
            });
        })

        console.log(triggerEl.dataset['bsTarget']);
    })

    const dvModal = new DvModal();
    dvModal.init();
});

class GreenGreenMap {
    initPresets(ymaps) {
        ymaps.option.presetStorage.add('custom#city-containers', {
            iconLayout: 'default#image',
            iconImageHref: '/img/icon/city-container-pin-v2.svg',
            iconImageSize: [40, 50],
            iconImageOffset: [-20, -48],
            iconColor: '#4D8BFF'
        });
        ymaps.option.presetStorage.add('custom#partner', {
            iconLayout: 'default#image',
            iconImageHref: '/img/icon/city-container-pin-v2.svg',
            iconImageSize: [40, 50],
            iconImageOffset: [-20, -48],
            iconColor: '#4D8BFF'
        });
        ymaps.option.presetStorage.add('custom#ecocenter', {
            iconLayout: 'default#image',
            iconImageHref: '/img/icon/ecocenter-pin.svg',
            iconImageSize: [40, 50],
            iconImageOffset: [-20, -48],
            iconColor: '#4B764A'
        });
        ymaps.option.presetStorage.add('custom#textile', {
            iconLayout: 'default#image',
            iconImageHref: '/img/icon/textile-pin-v2.svg',
            iconImageSize: [40, 50],
            iconImageOffset: [-20, -48],
            iconColor: '#FFC600'
        });
        ymaps.option.presetStorage.add('custom#organic', {
            iconLayout: 'default#image',
            iconImageHref: '/img/icon/organic-pin.svg',
            iconImageSize: [40, 50],
            iconImageOffset: [-20, -48],
            iconColor: '#4B764A'
        });
        ymaps.option.presetStorage.add('custom#electronics', {
            iconLayout: 'default#image',
            iconImageHref: '/img/icon/electronics-pin-v2.svg',
            iconImageSize: [40, 50],
            iconImageOffset: [-20, -48],
            iconColor: '#FF6127'
        });
        ymaps.option.presetStorage.add('custom#battery', {
            iconLayout: 'default#image',
            iconImageHref: '/img/icon/battery-pin.svg',
            iconImageSize: [40, 50],
            iconImageOffset: [-20, -48],
            iconColor: '#610099'
        });
        ymaps.option.presetStorage.add('custom#electronics-battery', {
            iconLayout: 'default#image',
            iconImageHref: '/img/icon/electronics-battery-pin.svg',
            iconImageSize: [40, 50],
            iconImageOffset: [-20, -48],
            iconColor: '#990075'
        });
        ymaps.option.presetStorage.add('custom#malls', {
            iconLayout: 'default#image',
            iconImageHref: '/img/icon/malls-pin.svg',
            iconImageSize: [40, 50],
            iconImageOffset: [-20, -48],
            iconColor: '#FF0000'
        });
        ymaps.option.presetStorage.add('custom#bottles', {
            iconLayout: 'default#image',
            iconImageHref: '/img/icon/bottles-pin-v2.svg',
            iconImageSize: [40, 50],
            iconImageOffset: [-20, -48],
            iconColor: '#00D4FF'
        });
        ymaps.option.presetStorage.add('custom#bottles-paper', {
            iconLayout: 'default#image',
            iconImageHref: '/img/icon/bottles-paper-pin.svg',
            iconImageSize: [40, 50],
            iconImageOffset: [-20, -48],
            iconColor: '#007F99'
        });
        ymaps.option.presetStorage.add('custom#cleanup', {
            iconLayout: 'default#image',
            iconImageHref: '/img/icon/cleanup-pin.svg',
            iconImageSize: [30, 40],
            iconImageOffset: [-15, -35],
            iconColor: '#4B764A'
        });
    }
    static async init(blockId, options) {
        if (!window['ymaps']) {
            document.getElementById(blockId).innerHTML = '<div class="alert alert-warning">The map is not loaded, most likely due to your ad blocker configuration.</div>';
            return;
        }
        let ggmap = new GreenGreenMap(blockId, options);
        ggmap.handleUrlHash();
        await ggmap.preloadData();
        ggmap.render();
    }
    balloonId = null;
    filter = null;
    filterTypes = null;
    constructor(blockId, options) {
        this.block = document.getElementById(blockId);
        this.blockId = blockId;
        this.filterTypes = options.filter_types ?? null;
        this.filtersBlock = options.filters_block_id ? document.getElementById(options.filters_block_id) : null;
        this.points = options.points ?? null;
        this.pointsUrl = options.points_url ?? null;
        this.location = { center: options.center, zoom: options.zoom };
        this.config = {
            maxZoom: options.maxZoom,
            minZoom: options.minZoom,
        }
    }
    handleUrlHash() {
        let hash = window.location.hash.substring(1).split(',');
        let i = 0;
        if (hash.length >= 3) {
            if (hash[0] && hash[1] && hash[2]) {
                this.location = {
                    center: [parseFloat(hash[0]), parseFloat(hash[1])],
                    zoom: parseInt(hash[2]),
                };
            }
            i = 3;
        }
        for (let j = i; j < hash.length; j++) {
            if (hash[j].startsWith('id=')) {
                this.balloonId = parseInt(hash[j].split('=')[1]);
            }
            if (hash[j].startsWith('f=')) {
                this.filter = hash[j].split('=')[1];
            }
        }
        this.refreshFiltersBlock();
    }
    async preloadData() {
        if (this.pointsUrl) {
            this.block.classList.add('placeholder');
            this.block.classList.add('placeholder-wave');
            const pointsResponse = await fetch(this.pointsUrl);
            this.points = await pointsResponse.json();
            this.block.classList.remove('placeholder-wave');
            this.block.classList.remove('placeholder');
        }
        if (this.filtersBlock) {
            this.filtersBlock.classList.add('show');
        }
    }
    refreshFiltersBlock() {
        if (this.filtersBlock) {
            const btn = document.getElementById('map-filters-button')?.querySelector('.btn') ?? null;
            for (let item of this.filtersBlock.querySelectorAll('.nav-link')) {
                if (!this.filter && item.dataset['filter'] === 'any') {
                    item.classList.add('active');
                    if (btn) {
                        btn.innerHTML = '<i class="far fa-filter"></i>';
                        btn.classList.add('btn-outline-primary');
                        btn.classList.remove('btn-primary');
                    }
                } else if (this.filter && this.filter === item.dataset['filter']) {
                    item.classList.add('active');
                    if (btn) {
                        btn.innerHTML = '<i class="far fa-fw fa-filter"></i> ' + item.innerText;
                        btn.classList.add('btn-primary');
                        btn.classList.remove('btn-outline-primary');
                    }
                } else {
                    item.classList.remove('active');
                }
            }
        }
    }
    render() {
        const ymaps = window['ymaps'];

        if (this.filtersBlock) {
            for (let item of this.filtersBlock.querySelectorAll('.nav-link')) {
                item.addEventListener('click', (e) => {
                    if (!e.target.dataset['filter'] || e.target.dataset['filter'] === 'any') {
                        this.filter = null;
                    } else {
                        this.filter = e.target.dataset['filter'];
                    }
                    this.filtersBlock.classList.remove('show-forced');
                    this.refreshFiltersBlock();
                    this.filterPoints();
                });
            }
            for (let item of this.filtersBlock.querySelectorAll('.btn-close')) {
                item.addEventListener('click', (e) => {
                    this.filtersBlock.classList.remove('show-forced');
                });
            }
        }

        if (this.balloonId) {
            let object = this.points['features'].find((element) => element.id === this.balloonId);
            if (object) {
                this.location.center = object.geometry.coordinates;
                this.location.zoom = 16;
            }
        }

        ymaps.ready(() => {
            this.initPresets(ymaps);
            this.map = new ymaps.Map(this.blockId, {
                center: this.location.center,
                zoom: this.location.zoom,
                controls: [/*'zoomControl',*/ 'geolocationControl', 'searchControl'],
            }, {
                maxZoom: this.config.maxZoom,
                minZoom: this.config.minZoom,
                searchControlProvider: 'yandex#search',
            });
            if (this.filtersBlock) {
                let self = this;
                const FilterButtonLayout = ymaps.templateLayoutFactory.createClass('<div id="map-filters-button"><button class="btn btn-outline-primary"><i class="far fa-filter"></i></button></div>', {
                    build: function () {
                        FilterButtonLayout.superclass.build.call(this);
                        self.refreshFiltersBlock();
                    },
                })
                let filterButton = new ymaps.control.Button({
                    data: { },
                    options: { selectOnClick: false, layout: FilterButtonLayout, maxWidth: 250 }
                });
                filterButton.events.add('click', () => {
                    this.filtersBlock.classList.add('show-forced');
                });
                this.map.controls.add(filterButton, { float: 'right', floatIndex: 1000 });
            }
            this.objectManager = new ymaps.ObjectManager({
                clusterize: true,
                gridSize: 80,
                clusterDisableClickZoom: false,
                clusterIconLayout: 'default#pieChart',
                clusterIconPieChartRadius: 22,
                clusterIconPieChartCoreRadius: 16,
                clusterIconPieChartStrokeWidth: 3,
                clusterBalloonContentLayout: "cluster#balloonCarousel",
                clusterBalloonCycling: false,
                clusterBalloonPagerType: "marker",
                clusterBalloonPagerSize: 6,
                hideIconOnBalloonOpen: true,
                zoomMargin: 100
            });
            this.objectManager.objects.events.add('balloonopen', (e) => {
                let object = this.objectManager.objects.getById(e.get('objectId'));
                if (object) {
                    this.balloonId = object.id;
                    this.refreshLocationHash();
                }
                document.querySelectorAll('.point-image').forEach(el => {
                    el.querySelectorAll('img').forEach(img => img.addEventListener('load', () => el.classList.remove('placeholder')));
                    el.addEventListener('click', Lightbox.initialize)
                });
            });
            this.objectManager.objects.events.add('balloonclose', () => {
                this.balloonId = null;
                this.refreshLocationHash();
            });
            this.map.geoObjects.add(this.objectManager);
            this.objectManager.add(this.points);
            this.map.events.add('boundschange', () => this.refreshLocationHash());

            this.filterPoints();
            if (this.balloonId) {
                this.objectManager.objects.balloon.open(this.balloonId);
            }
        });
    }
    refreshLocationHash() {
        let hash = [ ];
        if (this.balloonId) {
            hash.push('id=' + this.balloonId);
        } else {
            let center = this.map.getCenter();
            let zoom = this.map.getZoom();
            hash.push(center[0].toFixed(5));
            hash.push(center[1].toFixed(5));
            hash.push(zoom);
        }
        if (this.filter) {
            hash.push('f=' + this.filter);
        }
        window.location.hash = hash.join(',');
    }
    filterPoints() {
        if (this.filter) {
            let selectedTypes = this.filterTypes[this.filter] ?? [ ];
            this.objectManager.setFilter((object) => {
                return selectedTypes.includes(object.properties.category);
            });
        } else {
            this.objectManager.setFilter(() => true);
        }
        this.refreshLocationHash();
    }
}
