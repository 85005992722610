async function displayEventMapWidget(blockId, lat, long, url) {
    if (!window['ymaps3']) {
        document.getElementById(blockId).innerHTML = '<div class="alert alert-warning">The map is not loaded, most likely due to your ad blocker configuration.</div>';
        return;
    }

    await ymaps3.ready;
    const {YMap, YMapDefaultSchemeLayer, YMapDefaultFeaturesLayer, YMapMarker, YMapControls} = ymaps3;
    const {YMapGeolocationControl} = await ymaps3.import('@yandex/ymaps3-controls@0.0.1');
    const {YMapZoomControl} = await ymaps3.import('@yandex/ymaps3-controls@0.0.1');

    let map = new YMap(
        document.getElementById(blockId), {
            location: { center: [ long, lat ], zoom: 16 },
            showScaleInCopyrights: true
        }, [
            new YMapDefaultSchemeLayer({}),
            new YMapDefaultFeaturesLayer({}),
            new YMapControls({ position: 'top left' }).addChild(new YMapGeolocationControl({})),
            new YMapControls({ position: 'left' }).addChild(new YMapZoomControl({}))
        ]
    );

    const markerElement = document.createElement('div');
    markerElement.className = 'map-marker-cleanup';
    markerElement.onclick = () => window.open(url ? url : 'https://maps.yandex.ru/?text=' + lat + ',' + long, '_blank');
    map.addChild(new YMapMarker({coordinates: [ long, lat ]}, markerElement));
}